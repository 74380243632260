'use strict';

function MainNavigationItem(node){
	this.$node = null;
	this.$title = null;
	this.$body = null;
	this._timer = -1;
	this.init(node);
};
MainNavigationItem.prototype.init = function(node){
	this.initNode(node);
	this.initEvent();
};
MainNavigationItem.prototype.initNode = function(node){
	this.$node = $(node);
	this.initTitle();
	this.initBody();
};
MainNavigationItem.prototype.initTitle = function(){
	this.$title = this.$node.find('.category-title').eq(0);
	this.$title.attr({'tabindex': 0});
};
MainNavigationItem.prototype.initBody = function(){
	this.$body = this.$node.find('dd').eq(0);
};
MainNavigationItem.prototype.initEvent = function(){
	this.$node
		.on('mouseenter', $.proxy(this.open, this))
		.on('mouseleave', $.proxy(this.close, this))
	;

	this.$title
		.on('focus', $.proxy(this.open, this))
		.on('blur', $.proxy(this.close, this))
	;

	this.$body.find('a')
		.on('focus', $.proxy(this.open, this))
		.on('blur', $.proxy(this.close, this))
	;
};
MainNavigationItem.prototype.open = function(){
	this.clearTimer();
	this.$body.addClass('open');
};
MainNavigationItem.prototype.close = function(){
	this.clearTimer();
	this.timer = setTimeout($.proxy(function(){
		this.$body.removeClass('open');
	}, this));
};
MainNavigationItem.prototype.clearTimer = function(){
	clearTimeout(this.timer);
}

window.MainNavigationItem = MainNavigationItem;