'use strict';

function TableFrame(node){
	this.$node = null;
	this.$wrap = null;
	this.$table = null;
	this.tableWidth = 0;
	this.init(node);
};
TableFrame.prototype.init = function(node){
	this.initNode(node);
	this.initEvent();
	this.applyClass();
};
TableFrame.prototype.initNode = function(node){
	this.$node = $(node);
	this.initWrap();
	this.initTable();
};
TableFrame.prototype.initWrap = function(){
	this.$wrap = this.$node.find('.table-wrap').eq(0);
};
TableFrame.prototype.initTable = function(){
	this.$table = this.$node.find('.table').eq(0);
	this.initWidth();
};
TableFrame.prototype.initWidth = function(){
	this.$node.css({ overflow: 'hidden' });
	this.$wrap.css({ width: '999999px' });

	this.tableWidth = this.$table.innerWidth() - 1;
	this.$table.css({ width: this.tableWidth + 'px' });

	this.$wrap.css({ width: '' });
	this.$node.css({ overflow: '' });
};
TableFrame.prototype.initEvent = function(){
	$(window).on('resize', $.proxy(this.applyClass, this));
};
TableFrame.prototype.applyClass = function(){
	var frameWidth = this.$node.width();
	if (this.tableWidth > frameWidth) {
		this.$node.addClass('scrollable');
	} else {
		this.$node.removeClass('scrollable');
	}
};

window.TableFrame = TableFrame;
