require('./GetUrlQueries');
'use strict';

function SubcategorySelector(node){
	this.$node      = null;
	this.$duplicate = null;
	this.$list      = null;
	this.value      = '';
	this.init(node);
};
SubcategorySelector.prototype.init = function(node){
	this.initNode(node);
	this.initEvent();
	this.initCategory();
};
SubcategorySelector.prototype.initNode = function(node){
	this.$node = $(node);

	var htmlFor = this.$node.attr('data-subcategory-selector-for');

	this.$duplicate = $('.duplicate[data-subcategory-selector-for="' + htmlFor + '"]');
	this.$list = $(htmlFor);
};
SubcategorySelector.prototype.initEvent = function(){
	var onChange = $.proxy(this.onChange, this);
	this.$node.find('input[type="checkbox"]').on('change', onChange);
	this.$duplicate.find('input[type="checkbox"]').on('change', onChange);
};
SubcategorySelector.prototype.initCategory = function(){
	if(this.$node.length <= 0) { return };

	var queries = getUrlQueries();
	if (queries && queries.category) {
		this.setValue(queries.category);
	}
}
SubcategorySelector.prototype.setValue = function(value){
	if (this.value !== value) {
		this.value = value;
    history.replaceState('','','index.html?category=' + value);
		var selectFunc = function(index, node){
			node.checked = (node.value === value);

			var $icon = $(node).closest('.subcategory').find('.subcategory-icon');
			var src = $icon.attr('src');

			var replaceSrc = '';
			if (node.checked) {
				replaceSrc = src.replace(/(_on)?(\.(jpe?g|gif|png))$/i, '_on$2');
			} else {
				replaceSrc = src.replace(/_on(\.(jpe?g|gif|png))$/i, '$1');
			}

			if (src !== replaceSrc) {
				$icon.attr({ src: replaceSrc });
			}
		};

		this.$node.find('input[type="checkbox"]').each(selectFunc);
		this.$duplicate.find('input[type="checkbox"]').each(selectFunc);

		this.filter();
	}
};
SubcategorySelector.prototype.filter = function(){
	var value = this.value;
	var filterFunc = function(el){ return el === value };
	this.$list.find('.article-item').each(function(index, node){
		var $item = $(node);
		var categories = ($item.attr('data-subcategory') || '').split(',');
		if (!value || categories.some(filterFunc)) {
			$item.removeClass('hidden');
			$item.removeAttr('aria-hidden');
		} else {
			$item.addClass('hidden');
			$item.attr({ 'aria-hidden' : true });
		}
	});
};
SubcategorySelector.prototype.onChange = function(e){
	var value = e.currentTarget.checked ? $(e.currentTarget).val() : '';
	this.setValue(value);
};

window.SubcategorySelector = SubcategorySelector;