function topSlider() {
  new Swiper('.top-slider .swiper-container', {
    slidesPerView: 2,
    spaceBetween: 20,
    centeredSlides: true,
    centeredSlidesBounds: true,
    speed: 500,
    autoplay: {
      delay: 4000,
    },
    loop: true,
    pagination: {
      el: '.top-slider .swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      prevEl: '.top-slider .button-prev',
      nextEl: '.top-slider .button-next',
    }
  })
}


window.topSlider = topSlider;