'use strict';

function EmbededPlayer(node){
	this.$node = null;
	this.init(node);
};
EmbededPlayer.prototype.init = function(node){
	this.initNode(node);
	this.initEvent();
	this.onResize();
};
EmbededPlayer.prototype.initNode = function(node){
	this.$node = $(node);
};
EmbededPlayer.prototype.initEvent = function(){
	$(window).on('resize', $.proxy(this.onResize, this));
};
EmbededPlayer.prototype.onResize = function(){
	var defaultWidth = parseInt(this.$node.attr('width'));
	var defaultHeight = parseInt(this.$node.attr('height'));
	var ratio = defaultHeight / defaultWidth;
	this.$node.css({ height : this.$node.parent().width() * ratio });
};

window.EmbededPlayer = EmbededPlayer;