'use strict';

function HeaderSearchForm(node){
	this.$node = null;
	this.$keyword = null;
	this.init(node);
};
HeaderSearchForm.prototype.init = function(node){
	this.initNode(node);
	this.initEvent();
};
HeaderSearchForm.prototype.initNode = function(node){
	this.$node = $(node);
	this.$keyword = this.$node.find('.keyword');
};
HeaderSearchForm.prototype.initEvent = function(){
	$('.form-open').on('click', $.proxy(this.onClickOpen, this));
	this.$node.find('.form-close').on('click', $.proxy(this.onClickClose, this));
};
HeaderSearchForm.prototype.open = function(){
	this.$node.removeAttr('aria-hidden');
	this.$keyword.focus();
};
HeaderSearchForm.prototype.close = function(){
	this.$node.attr({ 'aria-hidden': 'true'});
};
HeaderSearchForm.prototype.onClickOpen = function(e){
	e.preventDefault();
	this.open();
};
HeaderSearchForm.prototype.onClickClose = function(e){
	e.preventDefault();
	this.close();
};

window.HeaderSearchForm = HeaderSearchForm;