function PDFViewer(node){
	this.$node = null;
	this.canvas = null;
	this.context = null;

	this.url = '';
	this.scale = 1;
	this.currentPage = 1;
	this.totalPages = 0;

	this.pdf = null;
	this.page = null;
	this.viewport = null;

	this.loadingTask = null;
	this.renderTask = null;

	this.cache = null;

	this.init(node);
};
PDFViewer.prototype.lib = window['pdfjs-dist/build/pdf'];
if (PDFViewer.prototype.lib) {
	PDFViewer.prototype.lib.GlobalWorkerOptions.workerSrc = '/common/js/pdf.worker.js';
}
PDFViewer.prototype.init = function(node){
	this.initNode(node);
	this.initCanvas();
	this.initContext();
};
PDFViewer.prototype.initNode = function(node){
	this.$node = $(node);
};
PDFViewer.prototype.initCanvas = function(){
	this.canvas = this.$node.get(0);
};
PDFViewer.prototype.initContext = function(){
	this.context = this.canvas.getContext('2d');
};
PDFViewer.prototype.clear = function(){
	this.clearLoadingTask();
	this.clearRenderTask();

	this.currentPage = 1;
	this.totalPages = 0;
	this.pdf = null;
	this.page = null;
	this.viewport = null;
	this.cache = null;
};
PDFViewer.prototype.clearLoadingTask = function(){
	if (this.loadingTask) {
		this.loadingTask.destroy();
		this.loadingTask = null;
	}
};
PDFViewer.prototype.clearRenderTask = function(){
	if (this.renderTask) {
		this.renderTask.cancel();
		this.renderTask = null;
	}
};
PDFViewer.prototype.load = function(url){
	this.clear();

	this.url = url || '';

	if (!this.url) {
		return;
	}

	this.loadingTask = this.lib.getDocument(this.url);
	this.loadingTask.promise.then($.proxy(this.onLoad, this), $.proxy(this.onLoadError, this));

	this.onBeforeLoad();
};
PDFViewer.prototype.render = function(pageNumber){
	if (pageNumber < 1) {
		pageNumber = 1;
	} else if (pageNumber > this.totalPages) {
		pageNumber = this.totalPages;
	}

	this.clearRenderTask();

	$(this).trigger('PDFViewer.beforerender');

	if (this.cache && this.cache[pageNumber]) {
		this.onGetPage(this.cache[pageNumber]);
		return (new Promise(function(resolve, reject){ resolve() }));
	} else {
		return this.pdf.getPage(pageNumber).then($.proxy(this.onGetPage, this), $.proxy(this.onGetPageError, this));
	}
};
PDFViewer.prototype.renderPrev = function(){
	if (this.currentPage > 1) {
		return this.render(this.currentPage - 1);
	}
};
PDFViewer.prototype.renderNext = function(){
	if (this.currentPage < this.totalPages) {
		return this.render(this.currentPage + 1);
	}
};
PDFViewer.prototype.setCurrentPage = function(pageNumber){
	this.currentPage = pageNumber;
	$(this).trigger('PDFViewer.pagechange');
};
PDFViewer.prototype.onBeforeLoad = function(){
	$(this).trigger('PDFViewer.beforeload');
};
PDFViewer.prototype.onLoad = function(pdf){
	this.loadingTask = null;

	this.pdf = pdf;
	this.totalPages = pdf.numPages;

	$(this).trigger('PDFViewer.load');

	this.render(1);
};
PDFViewer.prototype.onLoadError = function(err){
	console.error(err);
};
PDFViewer.prototype.onGetPage = function(page){
	this.setCurrentPage(page.pageNumber);

	if (!this.cache) {
		this.cache = {};
	}

	this.cache[page.pageNumber] = page;

	this.page = page;

	this.viewport = page.getViewport({ scale: this.scale });

	this.canvas.width = this.viewport.width;
	this.canvas.height = this.viewport.height;

	this.renderTask = page.render({
		canvasContext: this.context,
		viewport: this.viewport
	});

	this.renderTask.promise.then($.proxy(this.onRender, this), $.proxy(this.onRenderError, this));
};
PDFViewer.prototype.onGetPageError = function(err){
//	console.log(err);
};
PDFViewer.prototype.onRender = function(){
	this.renderTask = null;
	$(this).trigger('PDFViewer.render');
};
PDFViewer.prototype.onRenderError = function(err){
//	console.log(err);
};

window.PDFViewer = PDFViewer
