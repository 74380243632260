 
function throttle(fn, interval) {
  var lastTime = Date.now() - interval
  return function() {
    if ((lastTime + interval) < Date.now()) {
      lastTime = Date.now()
      fn()
    }
  }
}

function initSmoothScroll() {
  var ignoreClass = 'no-scroll';
  $('a[href^="#"]').not('.' + ignoreClass).click(function(e){
    e.preventDefault();
    smoothScroll($(this).attr("href"));
    return false;
  });

  var $footer = $('#site-footer');
  var $button = $('.to-top-button');
  toggleToTopButtonState($footer, $button);
  var scrollHandler = throttle(function() { toggleToTopButtonState($footer, $button) }, 50);
  $(window).on('scroll', scrollHandler);
}

function toggleToTopButtonState($footer, $button) {
  var footerTop = $footer.get(0).getBoundingClientRect().top;
  var buttonTop = $button.get(0).getBoundingClientRect().top;
  if (footerTop < window.innerHeight - 80) {
    $button.addClass('fixed');
  } else {
    $button.removeClass('fixed');
  }
}

function smoothScroll(href) {
  var speed = 500;
  var target = href != "#" ? $(href) : false;
  var position = href == "#" ? 0: target.offset().top;
  var header = $('.p-header');
  var space = 80;
  if (header.length > 0) {
    position -= header.outerHeight();
  }
  position -= space;
  $('html, body').animate({scrollTop:position}, speed, "swing");
}


window.initSmoothScroll = initSmoothScroll;