function PDFViewerUI(node) {
	this.$node = null;
	this.$viewer = null;
	this.$ui = null;
	this.$currentPage = null;
	this.$totalPages = null;
	this.$prev = null;
	this.$next = null;
	this.$download = null;
	this.viewer = null;
	this.isActive = false;
	this.init(node);
};
PDFViewerUI.prototype.init = function(node){
	this.initNode(node);
	this.initViewer();
	this.initUI();
	this.initEvents();
	this.viewer.load(this.$node.attr('data-src') || '');
};
PDFViewerUI.prototype.initNode = function(node){
	this.$node = $(node);
};
PDFViewerUI.prototype.initViewer = function(){
	this.$viewer = $('<div class="pdf-viewer-ui-viewer"><canvas class="pdf-viewer-ui-canvas"></canvas></div>').appendTo(this.$node);
	this.viewer = new PDFViewer(this.$viewer.find('.pdf-viewer-ui-canvas').get(0));
};
PDFViewerUI.prototype.initUI = function(){
	this.$ui = $('<div class="pdf-viewer-ui"></div>').appendTo(this.$node);
	this.initPages();
	this.initButtons();
};
PDFViewerUI.prototype.initPages = function(){
	var $pages = $('<div class="pdf-viewer-ui-pages"><input class="pdf-viewer-ui-currentpage" type="number" value="1" step="1" min="1" title="ページ"><span class="pdf-viewer-ui-slash">/</span><span class="pdf-viewer-ui-totalpages">1</span></div>').appendTo(this.$ui);
	this.$currentPage = $pages.find('.pdf-viewer-ui-currentpage');
	this.$totalPages = $pages.find('.pdf-viewer-ui-totalpages');
};
PDFViewerUI.prototype.initButtons = function(){
	this.$prev = $('<button class="pdf-viewer-ui-prev" type="button" title="前のページ"><span>前のページ</span></button>').prependTo(this.$ui);
	this.$next = $('<button class="pdf-viewer-ui-next" type="button" title="次のページ"><span>次のページ</span></button>').appendTo(this.$ui);
	this.$download = $('<a class="pdf-viewer-ui-download" title="ダウンロード"><span>Download</span></a>').appendTo(this.$ui);
};
PDFViewerUI.prototype.initEvents = function(){
	this.initViewerEvents();
	this.initUIEvents();
};
PDFViewerUI.prototype.initViewerEvents = function(){
	this.$viewer.on('click', $.proxy(this.onClickViewer, this));
	$(this.viewer)
		.on('PDFViewer.beforeload', $.proxy(this.onBeforeLoad, this))
		.on('PDFViewer.load', $.proxy(this.onLoad, this))
		.on('PDFViewer.pagechange', $.proxy(this.onPageChange, this))
		.on('PDFViewer.beforerender', $.proxy(this.onBeforeRender, this))
		.on('PDFViewer.render', $.proxy(this.onRender, this))
	;
};
PDFViewerUI.prototype.initUIEvents = function(){
	this.$currentPage.on('change', $.proxy(this.onChangeCurrentPage, this));
	this.$prev.on('click', $.proxy(this.onClickPrev, this));
	this.$next.on('click', $.proxy(this.onClickNext, this));
};
PDFViewerUI.prototype.activate = function(){
	this.isActive = true;
	this.$currentPage.removeAttr('disabled');
	this.activateNav();
};
PDFViewerUI.prototype.activateNav = function(){
	if (this.isActive) {
		if (this.viewer.currentPage < 2) {
			this.$prev.attr({ disabled: true });
		} else {
			this.$prev.removeAttr('disabled');
		}

		if (this.viewer.currentPage > this.viewer.totalPages - 1) {
			this.$next.attr({ disabled: true });
		} else {
			this.$next.removeAttr('disabled');
		}
	}
};
PDFViewerUI.prototype.deactivate = function(){
	this.isActive = false;
	this.$currentPage.attr({ disabled: true });
	this.$prev.attr({ disabled: true });
	this.$next.attr({ disabled: true });
};
PDFViewerUI.prototype.onBeforeLoad = function(){
	this.$node.addClass('loading');
	this.$download.removeAttr('href', 'download', 'type');
	this.deactivate();
};
PDFViewerUI.prototype.onLoad = function(){
	this.$node.removeClass('loading');
	this.$currentPage.attr({ max: this.viewer.totalPages });

	var filename = this.viewer.url.match(/([^/?#]+)(¥?[^/#]*?)?(#.*?)?$/)[1];
	this.$download
		.attr({
			href: this.viewer.url,
			download: filename,
			type: 'application/pdf'
		})
	;

	this.activate();
};
PDFViewerUI.prototype.onPageChange = function(){
	this.$currentPage.val(this.viewer.currentPage);
	this.$totalPages.text(this.viewer.totalPages);
	this.activateNav();
};
PDFViewerUI.prototype.onBeforeRender = function(){
	this.isActive = false;
	this.$node.addClass('rendering');
};
PDFViewerUI.prototype.onRender = function(){
	this.$node.removeClass('rendering');
	this.activate();
};
PDFViewerUI.prototype.onChangeCurrentPage = function(e){
	if (this.viewer.pdf && this.isActive) {
		this.viewer.render(parseInt(this.$currentPage.val(), 10) || 1);
	}
};
PDFViewerUI.prototype.onClickPrev = function(e){
	e.preventDefault();
	if (this.viewer.pdf && this.isActive) {
		this.viewer.renderPrev();
	}
};
PDFViewerUI.prototype.onClickNext = function(e){
	e.preventDefault();
	if (this.viewer.pdf && this.isActive) {
		this.viewer.renderNext();
	}
};
PDFViewerUI.prototype.onClickViewer = function(e){
	if (this.viewer.pdf && this.isActive) {
		var center = this.$viewer.width() / 2;
		if (e.offsetX < center) {
			this.viewer.renderPrev();
		} else {
			this.viewer.renderNext();
		}
	}
};

window.PDFViewerUI = PDFViewerUI;
