require('./jquery-3.4.1.min.js');
require('./swiper');
require('./HeaderSearchForm');
require('./MainNavigationItem');
require('./TableFrame');
require('./PDFViewer');
require('./PDFViewerUI');
require('./SubcategorySelector');
require('./EmbededPlayer');
require('./SmoothScroll');
require('./topSlider');

'use strict';

jQuery(function ($) {
	new HeaderSearchForm($('#header-search-form'));
	$('#site-header .category dl').each(function (index, node) {
		new MainNavigationItem(node);
	});
	$('.table-frame').each(function (index, node) {
		new TableFrame(node);
	});
	$('.pdf-viewer').each(function (index, node) {
		new PDFViewerUI(node);
	});
	$('.subcategory-selector:not(.duplicate)').each(function (index, node) {
		new SubcategorySelector(node);
	});
	$('.picture').find('iframe').each(function (index, node) {
		new EmbededPlayer(node);
	});

	topSlider();
	initSmoothScroll();
});
